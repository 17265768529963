<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Харилцагчийн нэвтрэх эрхийн мэдээлэл - {{ this.total }}
        <p style="color:red; font-size: 10px; margin: 0;">
          - Email баталгаажаагүй харилцагчийн нууц үгийг сэргээх боломжгүй !
        </p>
      </h3>
    </div>
    <el-row :gutter="0" v-loading="loading">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <el-row :gutter="0">
            <el-col :span="24">
              <el-row>
                <el-input
                  style="width: 200px;"
                  prefix-icon="el-icon-search"
                  v-model="outlet_name"
                  size="mini"
                  clearable
                  placeholder="Харилцагчийн нэр"
                  onfocus=""
                />
              </el-row>
              <el-table
                style="width: 100%"
                :data="
                  partnerList.filter(
                    data =>
                      !outlet_name ||
                      (!['', undefined, null].includes(data.outlet_name) &&
                        data.outlet_name
                          .toLowerCase()
                          .includes(outlet_name.toLowerCase()))
                  )
                "
              >
                <el-table-column type="index" label="№" width="80" />
                <el-table-column
                  prop="outlet_name"
                  label="Outlet name"
                  width="230"
                >
                  <template slot-scope="scope">
                    <a
                      :href="
                        'https://www.admin.toktok.mn/partner-info?_id=' +
                          scope.row.outlet_id
                      "
                      style="color: blue"
                      >{{ scope.row.outlet_name }}</a
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="username" label="Username" width="200" />
                <el-table-column
                  prop="email_verified"
                  label="Email баталгаажсан эсэх"
                  width="250"
                >
                  <template slot-scope="scope">
                    <el-tag
                      v-if="scope.row.email_verified === 'true'"
                      type="success"
                      >CONFIRMED</el-tag
                    >
                    <el-tag
                      v-if="scope.row.email_verified != 'true'"
                      type="danger"
                      >UNCONFIRMED</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="status"
                  label="Account status"
                  width="250"
                >
                  <template slot-scope="scope">
                    <el-tag
                      v-if="scope.row.status === 'CONFIRMED'"
                      type="success"
                      >Идэвхитэй</el-tag
                    >
                    <el-tag
                      v-if="scope.row.status === 'UNCONFIRMED'"
                      type="danger"
                      >Идэвхигүй</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="email" label="Email" width="400" />
              </el-table>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import services from "../helpers/services";
export default {
  created() {
    this.getCognitoUserList();
  },
  data() {
    return {
      partnerList: [],
      total: 0,
      outlet_name: null,
      email: "",
      loading: false
    };
  },
  methods: {
    getCognitoUserList() {
      this.loading = true;
      services.getCognitoUserList("client").then(response => {
        if (response.data.status === "success") {
          this.partnerList = response.data.data.allUsers;
          this.total = response.data.data.total;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
